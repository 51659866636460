import React from 'react';

type Props = {
  badge: {
    id: number;
    badge: any;
  };
};

const Badges = ({ badge }: Props) => {
  return (
    <div className="flex items-center">
      <img src={badge.badge.src} className="h-[150px]" />
    </div>
  );
};

export default Badges;
