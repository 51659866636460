import React from 'react';
import ClaysLogo from 'components/ClaysLogo';
import MarketingForm from './MarketingForm';
import SocialLinks from './SocialLinks';
import Badges from './Badges';
import Sitemap from './Sitemap';
import { Separator } from '../ui/separator';

import GPTWBagdeMono from 'public/images/badges/best-workplaces.png';
import BadgeCertification from 'public/images/badges/badge-certification.png';
import BadgeRetailHospitality from 'public/images/badges/badge-retail-hospitality.png';

const FOOTER_BADGES = [
  {
    id: 1,
    badge: GPTWBagdeMono,
  },
  {
    id: 2,
    badge: BadgeRetailHospitality,
  },
  {
    id: 3,
    badge: BadgeCertification,
  },
];

const Footer = () => {
  return (
    <footer className="pt-4 pb-8 px-0 bg-footer-texture bg-dark-green md:pt-8 md:px-0 md:pb-10 lg:pt-12">
      <section className="max-w-[1500px] mx-auto flex flex-col items-center gap-8">
        <ClaysLogo className="mx-auto !w-20 !h-20" />

        <SocialLinks />
      </section>

      <section className="flex flex-col lg:flex-row gap-20 items-center lg:items-start justify-evenly mt-20 max-w-[1500px] mx-auto px-8">
        <div className="flex justify-center items-center gap-20">
          <MarketingForm />
        </div>

        <div className="flex flex-col justify-between items-center gap-8">
          <Sitemap />

          <Separator className="bg-background/10" />

          <div className="flex gap-4 flex-wrap items-center justify-center">
            {FOOTER_BADGES.map(($badge) => (
              <Badges key={$badge.id} badge={$badge} />
            ))}
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
